<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <!-- <b-form-group
            id="input-group-posyandu"
            label="Posyandu:"
            label-for="input-posyandu"
          >
            <treeselect
              v-model="form.posyandu_id"
              :multiple="false"
              placeholder="Pilih Posyandu"
              :options="posyandu_list"
            />
            <small class="text-danger">{{ error.posyandu_id }}</small>
          </b-form-group> -->

          <b-form-group
            id="input-group-weighing-date"
            label="Tanggal Penimbangan:"
            label-for="input-weighing-date"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-weighing-date"
                  v-model="form.weighing_date"
                  label="Tanggal Penimbangan:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.weighing_date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.weighing_date }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-staff"
            label="Petugas Posyandu:"
            label-for="input-staff"
          >
            <treeselect
              v-model="form.staff_id"
              :multiple="false"
              placeholder="Pilih Petugas Posyandu"
              :options="staffs"
            />
            <small class="text-danger">{{ error.staff_id }}</small>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-mother-id"
            label="Ibu Hamil:"
            label-for="input-mother-id"
          >
            <treeselect
              v-model="form.mother_id"
              :multiple="false"
              placeholder="Pilih Ibu Hamil"
              :options="mothers"
            />
            <small class="text-danger">{{ error.mother_id }}</small>
          </b-form-group> -->

          <b-form-group
            id="input-group-trigger-modal-mother"
            label="Pilih Ibu:"
            label-for="input-trigger-modal-mother"
          >
            <b-form-input
              id="input-trigger-modal-mother"
              v-model="display.mother_name"
              placeholder="Pilih Ibu"
              readonly
              @click="$bvModal.show('modal-mother')"
            >
            </b-form-input>
            <small class="text-danger">{{ error.mother_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-weight"
            label="Berat Badan (Kg):"
            label-for="input-weight"
          >
            <b-form-input
              id="input-weight"
              v-model="form.weight"
              placeholder="Berat Badan"
            ></b-form-input>
            <small class="text-danger">{{ error.weight }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-is-vitamin"
            label="Pemberian Vitamin:"
            label-for="input-is-vitamin"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-is-vitamin"
              v-model="form.is_vitamin"
              :aria-describedby="ariaDescribedby"
              name="input-group-is-vitamin"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.is_vitamin }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-vitamin-list"
            label="Vitamin:"
            label-for="input-vitamin-list"
            v-if="form.is_vitamin == 1"
          >
            <treeselect
              @select="setVitamin"
              @deselect="removeVitamin"
              v-model="vitamin_list"
              :multiple="true"
              placeholder="Pilih Vitamin"
              :options="vitamin_types"
            />
            <small class="text-danger">{{ error.vitamin_list }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-p-13"
            label="Kekurangan Energi Kronis (Lila < 23,5 cm):"
            label-for="input-p-13"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-p-13"
              v-model="form.p13"
              :aria-describedby="ariaDescribedby"
              name="input-group-p-13"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.p13 }}</small>
          </b-form-group>

          <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/weighing-mothers')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>

    <!-- modal -->

    <b-modal id="modal-mother" size="xl" title="Pilih Ibu" hide-footer>
      <Table purpose="modal" @chooseMother="chooseMother" />
    </b-modal>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ApiService from "@/core/services/api.service.js";
import Table from "@/view/components/mothers/Table.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";
// import the component
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  components: {
    Table,
  },
  data() {
    return {
      error: {
        posyandu_id: "",
        mother_id: "",
        registration_number: "",
        weight: "",
        weighing_date: "",
        is_vitamin: "",
        vitamin_list: "",
        staff_id: "",
        description: "",
        p13: "",
      },
      display: {
        mother_name: "",
      },
      // input vitamin_list
      vitamin_types: [],
      vitamin_list: [],
      coll_vitamin_form: {
        id: "",
        name: "",
      },
      coll_vitamin: [],
      coll_vitamin_actv: [],
      // other
      menu2: false,
      posyandu_list: [],
      mothers: [],
      staffs: [],
    };
  },
  methods: {
    getSelectOption(form) {
      // set vitamin selected
      this.coll_vitamin = JSON.parse(form.vitamin_list);
      let vitamin_actv = [];
      for (let a = 0; a < this.coll_vitamin.length; a++) {
        vitamin_actv.push(this.coll_vitamin[a].id);
        if (a + 1 == this.coll_vitamin.length) {
          this.vitamin_list = vitamin_actv;
        }
      }
    },

    async getMotherOption() {
      let response = await module.setTreeSelectToddler("api/mothers");
      console.log("balita", response);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.mothers = response.data;
        this.mothers.unshift({
          label: "Pilih Ibu Hamil",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getPosyanduOption() {
      let response = await module.setTreeSelect("api/posyandu");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.posyandu_list = response.data;
        this.posyandu_list.unshift({
          label: "Pilih Posyandu",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getStaffOption() {
      let response = await module.setTreeSelect(
        `api/cadres?posyandu_id=${getUser().posyandu_id}`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.staffs = response.data;
        this.staffs.unshift({
          label: "Pilih Petugas Posyandu",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getVitaminOption() {
      let response = await module.setTreeSelect("api/vitamin-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.vitamin_types = response.data;
      }
    },

    setVitamin(evt) {
      console.log("evt", evt);
      this.coll_vitamin_form.id = evt.id;
      this.coll_vitamin_form.name = evt.label;

      let clone = { ...this.coll_vitamin_form };
      this.coll_vitamin.push(clone);
      console.log("coll_vitamin", this.coll_vitamin);
    },

    removeVitamin(event) {
      console.log(event);
      console.log(event.id);

      let a;
      for (a = 0; a < this.coll_vitamin.length; a++) {
        if (event.id == this.coll_vitamin[a].id) {
          this.coll_vitamin.splice(a, 1);
          console.log("hapus", this.coll_vitamin);
        }
      }
    },

    chooseMother(value) {
      this.form.mother_id = value.id;
      this.display.mother_name = value.name;
      console.log("form", this.form);
      this.$bvModal.hide("modal-mother");
    },

    async formOnsubmit() {
      if (this.form.is_vitamin == 1) {
        this.form.vitamin_list = JSON.stringify(this.coll_vitamin);
      } else {
        this.form.vitamin_list = "";
      }
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/weighing-mothers");
      }
    },
  },
  mounted() {
    this.getMotherOption();
    this.getVitaminOption();
    this.getPosyanduOption();
    this.getStaffOption();

    console.log("form", this.form);
  },
  watch: {
    form: function (newVal, oldVal) {
      this.getSelectOption(this.form);

      this.display.mother_name = this.form.mother_name;
    },
  },
};
</script>